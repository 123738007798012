import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CaseActivityCard from "@_components/CaseActivityCard/CaseActivityCard";
import styles from "./CaseActivitySection.module.css";
import { caseActivityActions } from "@_actions/caseActivity.actions";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import {
  Button,
  CircularProgress,
  DialogActions,
  FormControlLabel,
  Snackbar,
  Tooltip,
} from "@material-ui/core";
import CallbackLogItem from "@_components/CallbackLogItem/CallbackLogItem";
import LicenseLogItem from "@_components/LicenseLogItem/LicenseLogItem";
import { caseActions } from "@_actions/case.actions";
import { caseConstants } from "@_constants/case.constants";
import AreYouSurePopup from "@_components/popups/AreYouSurePopup/AreYouSurePopup";
import { Alert } from "@material-ui/lab";
import { catchError } from "@_utils/helpers";
import AddressPopup from "@_components/popups/AddressPopup/AddressPopup";
import InfoPopup from "@_components/popups/InfoPopup/InfoPopup";

function CaseActivitySection(props) {
  const { data } = props;
  const caseActivityReducer = useSelector((state) => {
    return state.caseActivityReducer;
  });
  const caseReducer = useSelector((state) => state.caseReducer);

  const dispatch = useDispatch();
  const addNoteState =
    caseActivityReducer &&
    caseActivityReducer.status &&
    caseActivityReducer.status.CASEACTIVITY_ADD === "success";
  const deleteNoteState =
    caseActivityReducer &&
    caseActivityReducer.status &&
    caseActivityReducer.status.CASEACTIVITY_DELETE === "success";

  const [addNoteDialogOpen, setAddNoteDialogOpen] = useState(false);
  const [openAddressPopup, setOpenAddressPopup] = useState(false);
  const [noteInput, setNoteInput] = useState(null);
  const [addNoteErrorActive, setAddNoteErrorActive] = useState(false);
  const [activitiesLoading, setActivitiesLoading] = useState(true);
  const [showFutureSection, setShowFutureSection] = useState(false);
  const [isAutomationPaused, setIsAutomationPaused] = useState(true);
  const [resumeAutomationPopup, setResumeAutomationPopup] = useState(false);
  const [sentFailedEmailType, setSentFailedEmailType] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openInfoPopup, setOpenInfoPopup] = useState(false);
  const [infoPopupText, setInfoPopupText] = useState("");
  const [infoPopupTitle, setInfoPopupTitle] = useState("");

  useState(false);
  const [scheduledActivities, setScheduledActivities] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
    setOpenErrorSnackbar(false);
    setErrorMessage("");
    setSuccessMessage("");
  };
  useEffect(() => {
    setShowFutureSection(false);
    if (data) {
      dispatch(caseActivityActions.getCaseActivities(data.id));
      setIsAutomationPaused(
        data.overview &&
          (data.overview.num_scheduled_emails_paused > 0 ||
            data.overview.num_scheduled_emails_failed > 0 ||
            data.overview.num_triggered_emails_failed > 0)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const responseStatusSendMail = caseReducer.status.CASE_SEND_EMAIL;
    const responseStatusSaveMail = caseReducer.status.CASE_SAVE_EMAIL_TO_LOG;
    if (
      ((responseStatusSendMail && responseStatusSendMail === "success") ||
        (responseStatusSaveMail && responseStatusSaveMail === "success")) &&
      sentFailedEmailType
    ) {
      dispatch(
        caseActions.decreaseFailed(
          data.id,
          sentFailedEmailType === caseConstants.CASEACTIVITY_TYPE_TRIGGERED
            ? "num_triggered_emails_failed"
            : "num_scheduled_emails_failed"
        )
      );
      setSentFailedEmailType("");
      handleResumeAutomationPopup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    caseReducer.status.CASE_SEND_EMAIL,
    caseReducer.status.CASE_SAVE_EMAIL_TO_LOG,
  ]);

  useEffect(() => {
    if (caseReducer.status.CASE_GENERATE_INVOICE === "success") {
      setSuccessMessage("Success!");
      setOpenSnackbar(true);
      setOpenAddressPopup(false);
      dispatch(caseActivityActions.getCaseActivities(data.id));
    } else if (catchError(caseReducer.status.CASE_GENERATE_INVOICE)) {
      setErrorMessage(caseReducer.status.CASE_GENERATE_INVOICE);
      setOpenErrorSnackbar(true);
    }
    dispatch(caseActivityActions.clearState("CASE_GENERATE_INVOICE"));
  }, [dispatch, data.id, caseReducer.status.CASE_GENERATE_INVOICE]);

  useEffect(() => {
    if (caseReducer.trigger_sending_email_failed) {
      setErrorMessage("Something went wrong. Email was not sent");
      setOpenErrorSnackbar(true);
      dispatch(caseActions.restoreTriggerEmailFailed());
    }
  }, [caseReducer.trigger_sending_email_failed, dispatch]);

  const saveNote = () => {
    if (!noteInput || noteInput.length === 0) setAddNoteErrorActive(true);
    else {
      dispatch(caseActivityActions.saveNote(data.id, noteInput));
    }
  };

  const handleResumeAutomationPopup = () => {
    if (
      (data.overview.num_scheduled_emails_failed === 1 ||
        data.overview.num_triggered_emails_failed === 1) &&
      !(scheduledActivities.length === 1)
    ) {
      setResumeAutomationPopup(true);
    }
  };

  const activateAutomation = () => {
    dispatch(
      caseActions.toggleAutomation(
        data.id,
        caseConstants.CASE_AUTOMATION_STATE_SCHEDULED
      )
    );
    setResumeAutomationPopup(false);
  };

  useEffect(() => {
    if (addNoteState) {
      setNoteInput(null);
      setAddNoteDialogOpen(false);
      setAddNoteErrorActive(false);
      dispatch(caseActivityActions.clearState("CASEACTIVITY_ADD"));
    }
  }, [addNoteState, dispatch]);

  useEffect(() => {
    if (deleteNoteState) {
      dispatch(caseActivityActions.getCaseActivities(data.id));
      dispatch(caseActivityActions.clearState("CASEACTIVITY_DELETE"));
    }
  }, [data.id, deleteNoteState, dispatch]);

  const deleteNote = (activity) => {
    dispatch(caseActivityActions.deleteNote(data.id, activity.id));
  };

  useEffect(() => {
    var state = caseActivityReducer.status.CASEACTIVITY_GET;
    if (state && state === "success") {
      setActivitiesLoading(false);
    }
    if (
      caseActivityReducer &&
      caseActivityReducer.activities &&
      caseActivityReducer.activities.length > 0
    ) {
      setScheduledActivities(
        caseActivityReducer.activities.filter(
          (x) =>
            x.content &&
            x.content.scheduled_at !== null &&
            x.content.scheduled_at !== undefined /**todo */ &&
            x.content.status !== caseConstants.CASE_ACTIVITY_STATE_SENT
        )
      );
    } else {
      setScheduledActivities([]);
    }
  }, [
    caseActivityReducer,
    caseActivityReducer.activities,
    caseActivityReducer.status.CASEACTIVITY_GET,
  ]);

  useEffect(() => {
    if (caseActivityReducer.status.INVOICE_CANCEL === "success") {
      setSuccessMessage("Success!");
      setOpenSnackbar(true);
    } else if (catchError(caseActivityReducer.status.INVOICE_CANCEL)) {
      setErrorMessage(caseActivityReducer.status.INVOICE_CANCEL);
      setOpenErrorSnackbar(true);
    }
    dispatch(caseActivityActions.clearState("INVOICE_CANCEL"));
  }, [caseActivityReducer.status.INVOICE_CANCEL, dispatch]);

  const renderList = () => {
    let items = [];
    items = caseActivityReducer.activities
      .filter(
        (x) =>
          (!x.content ||
            x.content.scheduled_at === undefined ||
            x.content.status === "FAILED" ||
            (x.type === "LOGIN" && !x.user_id)) &&
          !(x.type === "LOGIN" && x.user_id) &&
          Object.values(caseConstants).includes(x.type)
      )
      .map((x) => {
        return (
          <div className={styles.activity_container}>
            <CaseActivityCard
              deleteNote={deleteNote}
              activity={x}
              caseData={data}
            ></CaseActivityCard>
          </div>
        );
      });

    return items;
  };

  const onPauseToggleClicked = () => {
    dispatch(
      caseActions.toggleAutomation(
        data.id,
        isAutomationPaused
          ? caseConstants.CASE_AUTOMATION_STATE_SCHEDULED
          : caseConstants.CASE_AUTOMATION_STATE_PAUSED
      )
    );
  };

  const renderFutureList = () => {
    let items = scheduledActivities
      .filter((a) => a.content.status !== "FAILED")
      .sort((x1, x2) => {
        const date1 = new Date(x1.content.scheduled_at);
        const date2 = new Date(x2.content.scheduled_at);
        return date2 - date1;
      })
      .map((x) => {
        return (
          <div className={styles.activity_container}>
            <CaseActivityCard
              deleteNote={deleteNote}
              activity={x}
              caseData={data}
              key={x.id}
              resumeAutomation={() => setSentFailedEmailType(x.content.type)}
              dottedborder
            ></CaseActivityCard>
          </div>
        );
      });
    if (items && items.length >= 2 && showFutureSection === false) {
      items = items.slice(items.length - 1);
      items.unshift(
        <div className={styles.activity_container_more_button}>
          <Button
            variant="outlined"
            color="default"
            onClick={() => setShowFutureSection(true)}
            className={styles.add_note_button}
            key={"__unique_show_future_id__"}
          >
            Show{" "}
            {scheduledActivities.filter((a) => a.content.status !== "FAILED")
              .length - 1}{" "}
            more Scheduled Email
            {scheduledActivities.length - 1 === 1 ? "" : "s"}
          </Button>
        </div>
      );
    }
    return items;
  };

  const handleOpenAddressPopup = () => {
    if (
      caseActivityReducer.activities.some(
        (activity) =>
          activity.type === caseConstants.CASEACTIVITY_TYPE_INVOICE_GENERATED &&
          activity.content?.type === "ACTIVE"
      )
    ) {
      setInfoPopupText(
        "An Active Invoice already exitsts. Please cancel it before generating a new invoice"
      );
      setInfoPopupTitle("Cannot generate an Invoice");
      setOpenInfoPopup(true);
      return;
    } else if (!caseReducer.case[data.id]?.invoice_template_id) {
      setInfoPopupText(
        "No invoice template set for this case. Pleae assign an invoice template before generating an invoice"
      );
      setInfoPopupTitle("Cannot generate an Invoice");
      setOpenInfoPopup(true);
      return;
    } else setOpenAddressPopup(true);
  };

  function checkLicenses(findings) {
    const results = {
      total: 0,
      date_uploaded: "",
      status: "VERIFIED",
      note: "",
    };
    findings &&
      findings.map((finding, index) => {
        if (finding.license) {
          results.total++;
          results.date_uploaded = finding.license.date_uploaded;
          if (finding.license.note) results.note = finding.license.note;
          if (finding.license.state === "UNCHECKED")
            results.status = "UNCHECKED";
        }
        return finding;
      });
    return results;
  }
  const licenseSummary = checkLicenses(data.findings);

  return (
    <div className={styles.root}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      {errorMessage && (
        <Snackbar
          open={openErrorSnackbar}
          autoHideDuration={7000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
      {data.callback_request && (
        <CallbackLogItem data={data.callback_request} caseId={data.id} />
      )}

      {licenseSummary.status === "UNCHECKED" && (
        <LicenseLogItem
          data={data}
          caseId={data.id}
          summary={licenseSummary}
          resumeAutomation={() => {
            if (isAutomationPaused && !caseReducer.trigger_sending_email_failed)
              setResumeAutomationPopup(true);
          }}
        />
      )}
      <hr
        style={{
          backgroundColor: "var(--color-theme-cloud)",
          marginBottom: "15px",
        }}
      ></hr>

      <div className={styles.title_container}>
        <div className={styles.title_font}>Case Activities</div>
        <div className={styles.title_right_side}>
          <div className={styles.flex_right}>
            {scheduledActivities.length > 0 &&
            !(
              scheduledActivities.length === 1 &&
              scheduledActivities[0].content?.status ===
                caseConstants.CASE_ACTIVITY_STATE_FAILED
            ) ? (
              <FormControlLabel
                labelPlacement="start"
                disabled={
                  data.overview && data.overview.num_scheduled_emails_failed > 0
                }
                label={
                  isAutomationPaused
                    ? "Automation is paused"
                    : "Automation is active"
                }
                control={
                  <div>
                    <Tooltip
                      title={
                        data.overview &&
                        (data.overview.num_scheduled_emails_failed > 0 ||
                          data.overview.num_triggered_emails_failed > 0)
                          ? "Resolve failed emails before resuming"
                          : ""
                      }
                      placement="top"
                      arrow
                    >
                      <span>
                        <Button
                          style={{
                            marginLeft: "20px",
                            marginRight: "11px",
                            width: "101px",
                            color: "var(--color-font-10) !important",
                            borderColor: "var(--color-font-10) !important",
                            position: "relative",
                            zIndex: 99,
                          }}
                          disabled={
                            data.overview &&
                            (data.overview.num_scheduled_emails_failed > 0 ||
                              data.overview.num_triggered_emails_failed > 0)
                          }
                          className={styles.add_note_button}
                          variant="outlined"
                          color="default"
                          onClick={onPauseToggleClicked}
                        >
                          {isAutomationPaused ? "Resume" : "Pause"}
                        </Button>
                      </span>
                    </Tooltip>
                  </div>
                }
              ></FormControlLabel>
            ) : (
              <></>
            )}
          </div>
          <div>
            {data.state !== "STANDBY" && data.state !== "CANCELLED" && (
              <Button
                variant="outlined"
                color="default"
                style={{ marginRight: "25px" }}
                onClick={handleOpenAddressPopup}
                className={styles.add_note_button}
              >
                GENERATE INVOICE
              </Button>
            )}
            <Button
              variant="outlined"
              color="default"
              onClick={() => setAddNoteDialogOpen(true)}
              className={styles.add_note_button}
            >
              ADD NOTE
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.activity_list_container}>
        {activitiesLoading ? (
          <div className={styles.loading}>
            <CircularProgress style={{ color: "black" }} />
          </div>
        ) : (
          <>
            {
              <div className={styles.future_section_visible}>
                {renderFutureList()}
                {scheduledActivities.length > 0 ? (
                  <div className={styles.devider_container}>
                    <div className={styles.devider_border} />
                    <span className={styles.devider_content}>Now</span>
                    <div className={styles.devider_border} />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            }
            {renderList()}
          </>
        )}
      </div>

      <AddressPopup
        open={openAddressPopup}
        onClose={() => setOpenAddressPopup(false)}
        case={data}
      />

      <DialogPopup
        title={"Add Note"}
        open={addNoteDialogOpen}
        onClose={() => setAddNoteDialogOpen(false)}
      >
        <TextfieldInput
          label="Note"
          placeholder="Type Note here"
          onChange={(event) => {
            if (event) {
              setAddNoteErrorActive(false);
              setNoteInput(event.target.value);
            }
          }}
          multiline
          maxRows={6}
          rows={6}
          name={"add_note_input"}
          value={noteInput}
          error={addNoteErrorActive}
        ></TextfieldInput>
        <DialogActions>
          <div className="action_container">
            <Button
              onClick={() => {
                setNoteInput(null);
                setAddNoteDialogOpen(false);
                setAddNoteErrorActive(false);
              }}
              className="btn_cancel"
            >
              Cancel
            </Button>
            <Button onClick={saveNote} autoFocus className="btn_confirm">
              Save
            </Button>
          </div>
        </DialogActions>
      </DialogPopup>
      <AreYouSurePopup
        open={resumeAutomationPopup}
        close={() => setResumeAutomationPopup(false)}
        onClick={activateAutomation}
      >
        The email automation is currently paused. Do you want to resume it?
      </AreYouSurePopup>
      <InfoPopup
        open={openInfoPopup}
        close={() => setOpenInfoPopup(false)}
        title={infoPopupTitle}
      >
        <p>{infoPopupText}</p>
      </InfoPopup>
    </div>
  );
}

export default CaseActivitySection;
